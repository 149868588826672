<template>
    <div
        class="loading-screen fixed w-full h-full bg-black z-50 flex items-center"
    >
        <div class="mx-auto text-center pb-20">
            <SvgoLogo
                class="mx-auto block md:w-72 w-64 max-w-full mb-8"
                alt=""
            />
            <SvgoTailSpin
                class="mx-auto max-w-full w-16"
                alt=""
            />
        </div>
    </div>
</template>
