<script setup lang="ts">
useFont([
    {
        src: '/fonts/lato-regular.woff2',
        family: 'Lato',
        weight: '400 500',
        class: 'font-lato',
    },
    {
        src: '/fonts/lato-italic.woff2',
        family: 'Lato',
        weight: 'italic',
        class: 'font-lato',
    },
    {
        src: '/fonts/lato-bold.woff2',
        family: 'Lato',
        weight: '600 900',
        class: 'font-lato',
    },
    {
        src: '/fonts/germania-one.woff2',
        family: 'Germania One',
        weight: '100 900',
        class: 'font-germania-one',
    },
]);
</script>

<template>
    <div id="app">
        <Transition
            enter-active-class="transition ease-out duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <Suspense>
                <template #fallback>
                    <LoadingScreen />
                </template>
                <slot />
            </Suspense>
        </Transition>
    </div>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-display;
}

#__nuxt {
  height: 100%;
  width: 100%;
  position: relative;
}

#app {
  position: relative;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}
</style>
